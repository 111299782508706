.aboutContent {
    width: 100%;
    height: 100vh;
    flex-wrap: nowrap;
    display: flex;
}

.leftAbout {
    flex: 40%;
    padding: 20px 20px 20px 40px;
    display: flex;
    height: 100%;
}

.leftAbout img {
    max-width: 100%;
    max-height: 100%;
}

.rightAbout {
    flex: 60%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.title {
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 700;
    text-align: left;
    padding: 20px 0 0 40px;
}

.intro {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.13px;
    text-align: left;
}

.subTopic {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    padding-top: 20px;
}

.content {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 500;
    line-height: 15.26px;
    text-align: left;
}

.stats {
    text-align: left;
    font-family: NATS;
    font-weight: 400;
}

.stats h1 {
    margin: 0;
    font-size: 64px; 
}

.stats p {
    margin: 0px 0px 0px 20px;
    font-size: 20px;
}

.highlight {
    color: #FF9503;
}

.spacer {
    display: inline-block;
    width: 100px;
}

.countSpacer {
    display: inline-block;
    width: 50px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .aboutContent {
        flex-direction: column;
        height: auto;
    }

    .leftAbout, .rightAbout {
        flex: 100%;
        padding: 20px;
        height: auto;
    }

    .leftAbout {
        padding: 20px;
    }

    .rightAbout {
        padding: 20px;
    }

    .leftAbout img {
        width: 100%;
        height: auto;
    }

    .title {
        text-align: center;
        padding: 20px 0;
    }
}